@import 'gg-icons';

$color1: #39383a;
$color2: #257696;
$color3: #cc7809;
$bgcolor: #f6f4f5;
$hovbgcolor: #c9c9c9;
$hovcolor: #ffffff;

$toggle-width: 30px;
$toggle-icon-width: 24px;
$toggle-font-size: initial;

$mobile-max:767.98px;
$item-padding: 0px 10px;

pre {
    font-size: 10px;
    line-height: 11px;
}

@mixin rounded-corners {
    // border-radius: 15px;
    border-radius: 1em;
}

@mixin circular-sides {
    // border-radius: 50px;
    // border-radius: 15px;
    border-radius: 1em;
}

@mixin flat-left-side {
    border-radius: 0 50px 50px 0;
}

@mixin flat-right-side {
    border-radius: 50px 0 0 50px;
}

@mixin toggle-style {
    font-weight: bold;
    min-width: $toggle-width;
    min-height: $toggle-width;
    // position: absolute;
    position: relative;
    align-items: center;
    justify-content: center;
    @include flat-right-side;
    font-size: $toggle-font-size;
    // font-family: monospace;
    z-index: 99;
    // margin-left: 8px;
}

@mixin joli-expcol {
    color: #00000080;
    font-weight: bold;
    font-size: 24px;
    min-width: $toggle-icon-width;
    min-height: $toggle-icon-width;
    line-height: $toggle-icon-width;
    text-align: center;
    /* border: 1px solid #0000000f; */
    /* background: #0000000f; */
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: monospace;
}

#joli-toc-filler {
    display: none;
}

#joli-toc-wrapper {
    display: flex;
    position: fixed;
    z-index: 999;
    margin: 0;
    padding: 0;

    @at-root #joli-toc-header {
        cursor: pointer;
    }
    
    &.joli-incontent {
        position: initial;
        position: static;
        margin-bottom: 30px;
    }

    &.joli-folded {
        nav#joli-toc {
            #joli-toc-header {
                display: flex;
                padding: 0 10px;
            }

            #joli-toc-toggle {
                display: flex;
                cursor: pointer;
            }

            .title {
                display: flex;
                // margin-left: $toggle-width;
                // margin-right: $toggle-width;
                width: auto;
                flex: 1 1 auto;
                align-items: center;
            }

            ul.joli-nav {
                display: none;
            }
        }
    }

    &.joli-unfolded {}

    &.joli-floating {
        nav#joli-toc {
            display: none;

            &.joli-collapsed{
                li a.active +:not(.active) {
                    display: none;
                }
            }

            &.joli-collapsed,
            &.joli-expanded {
                display:inline;
                display:initial;
            }
        }
    }

    &.joli-unfolded-ufloating {
        nav#joli-toc {
            display:inline;
            display: initial;
        }
    }

    @media (max-width: $mobile-max) {
        &.joli-floating {
            display: flex;
            width: calc(100% - 40px);
            max-height: 90%;
            // height: 100%;
            left: 50%;
            transform: translateX(-50%);

            &:after {
                // content: '';
                // display: block;
                // height: 40px;
                // width: 100%;
                // bottom: 0;
                // position: absolute;
                // background: rgb(255,255,255);
                // background: linear-gradient(180deg, rgba(255,255,255,0.4) 0%, rgb(238, 238, 238) 70%);
            }

            .joli-expanded #joli-toc-collapse{
                display: block !important;
            }
        }
    }

    &.joli-folded-floating,
    &.joli-folded-incontent {
        &.joli-unfolded {
            nav#joli-toc #joli-toc-collapse {
                display: flex;
                @include toggle-style;
                min-height: $toggle-width - 6px;
                min-width: $toggle-width - 6px;
                font-size: $toggle-font-size;
                // background: #f1f1f1;
                border-radius: 5px;
                cursor: pointer;
            }
        }
    }

    nav#joli-toc.joli-expanded,
    &.joli-folded nav#joli-toc.joli-expanded,
    &.joli-unfolded nav#joli-toc {
        @include rounded-corners;
        padding: 10px;
        // min-width: 300px;

        .joli-div {
            //any item
            display: block;
        }

        #joli-toc-toggle,
        #joli-toc-toggle.active {
            display: none;
        }

        #joli-toc-header {
            display: flex;
        }
        
        .title {
            display: inline-block;
            // text-align: center;
            width: 100%;
            margin-left: 0;
            margin-left: initial;
            // padding: initial;
        }

        ul {
            display: block;
        }

        ul.joli-nav {
            column-rule-style: solid;
            column-rule-width: 1px;
            column-rule-color: #e9e9e9;
        
            ul {
                // margin: 0 0 0 15px;
                width: auto;
            }
        }

        li {
            display: block;
            position: relative;
            margin: 0;
            // height: auto;
        }

        li a {
            display: block;
            margin-bottom: 2px;
            padding: $item-padding;
            font-size: inherit; //font size
        }

        @media (max-width: $mobile-max) {
            width: 100%;
            max-height: 100%;
            overflow-y: auto;


            li a.active {
                margin-left: 0;
                @include circular-sides;
            }
        }
    }

    nav#joli-toc{
        // @include rounded-corners;
    }

    nav#joli-toc.joli-collapsed,
    nav#joli-toc {
        @include circular-sides;
        display: inline-block;
        background: $bgcolor;
        max-width: 100%;
        min-width: initial;
        // box-shadow: 0 0 10px #c2c2c2;
        // transition: all 0.2s cubic-bezier(0.47, 0, 0.49, 0.9);
        position: relative;
        font-size: 1em; //custom attribute


        .joli-div {
            //any item
            display: none;
        }

        #joli-toc-header {
            display: none;
            position: relative;
            /* min-height: 30px; */
            width: 100%;
            align-items: center;
        }
        
        #joli-toc-toggle-box{
            display: flex;
            align-items: center;

            .joli-expcol{
                @include joli-expcol;
            }

            &.toggle-align-left{
                // margin: 0 0 0 8px; //TRBL
            }

            &.toggle-align-right{
                // margin: 0 8px 0 0; //TRBL
            }
        }

        #joli-toc-toggle {
            display: none;
            @include toggle-style;
            // height: 100%;
        }

        #joli-toc-collapse {
            display: none;
        }

        //Displays toggle button on mobile
        // @media (max-width: $mobile-max) {

        //     #joli-toc-toggle.active {
        //         display: flex;
        //     }

        //     li a.active {
        //         margin-left: $toggle-width;
        //         @include flat-left-side;
        //     }
        // }

        .title {
            font-size: 1.1em;
            font-weight: 500;
            // text-align: center;
            width: 100%;
            display: none;
            padding: 0 0 0 0;
            padding: 0 10px;

            #title-label{
                display: block;
                width: 100%;
            }
        }

        ul {
            list-style-type: none;
            list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
            display: flex;
            position: relative;
            width: 100%;
        }

        ul.joli-nav {
            margin: 0;
            padding: 0;

            ul {
                margin: 0;
                padding: 0;
            }
        }

        li {
            // @include rounded-corners;
            // display: inline-flex;li.subitem {
            display: flex;
            // width: 100%;
            width: auto;
            margin: 0;
            padding: 0;
        }


        li a {
            font-size: 0px;
            // display: none;
            @include circular-sides;
            padding: 0;
            color: $color1;
            text-decoration: none;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            -webkit-transition: none;
            -moz-transition: none;
            -ms-transition: none;
            -o-transition: none;
            transition: none;

            &:before{
                color: #aaaaaa; //prefix
            }
        }

        li a.active {
            display: table-cell;
            color: $hovcolor;
            background: $color1;
            font-size: inherit;
            width: auto;
            padding: $item-padding;
            // @include flat-left-side;
        }

        li a:hover {
            color: $color1;
            background: $hovbgcolor;
        }

    }

    .joli-credits {
        font-size: 11px;
        margin: 0;
        padding: 0;
        position: relative;
        width: 100%;
        text-align: right;
        color: #bbbbbb;
        // filter: opacity(0.5);
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .joli-credits img {
        height: 12px;
        margin-left: 6px;
    }
}
/*
2 columns
#joli-toc-wrapper nav#joli-toc.joli-collapsed ul.joli-nav, #joli-toc-wrapper nav#joli-toc ul.joli-nav {
    font-size: 0.9em;
    column-count: 2;
    // column-gap: 1px; 
    column-fill: balance;
    column-rule-color: #aaaaaa80;
    column-rule-style: solid;
    column-rule-width: 1px;
    column-rule-style: dashed;
}*/
.gg-math-minus {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 16px;
    height: 2px;
    background: currentColor;
    border-radius: 10px
}

/*----------------------------*/
.gg-math-plus,
.gg-math-plus::after {
    display: block;
    box-sizing: border-box;
    background: currentColor;
    border-radius: 10px
}
.gg-math-plus {
    margin-top: -2px;
    position: relative;
    transform: scale(var(--ggs,1));
    width: 16px;
    height: 2px
}
.gg-math-plus::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 16px;
    top: -7px;
    left: 7px
}

/*----------------------------*/
.gg-chevron-down {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 100px
   }
   
   .gg-chevron-down::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    left: 4px;
    top: 2px
   } 

/*----------------------------*/
 .gg-chevron-up {
 box-sizing: border-box;
 position: relative;
 display: block;
 transform: scale(var(--ggs,1));
 width: 22px;
 height: 22px;
 border: 2px solid transparent;
 border-radius: 100px
}

.gg-chevron-up::after {
 content: "";
 display: block;
 box-sizing: border-box;
 position: absolute;
 width: 10px;
 height: 10px;
 border-top: 2px solid;
 border-right: 2px solid;
 transform: rotate(-45deg);
 left: 4px;
 bottom: 2px
} 
